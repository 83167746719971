<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" class="query-form">
      <el-form-item label="设备号" prop="device">
        <el-input
          v-model="queryParams.device"
          placeholder="请输入设备号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="车牌号" prop="vehicleNumber">
        <el-input
          v-model="queryParams.vehicleNumber"
          placeholder="请输入车牌号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="small" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="small"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="small"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="small"
          :disabled="multiple"
          @click="handleDelete"
        >删除线路</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="small"
          :loading="exportLoading"
          @click="handleExport"
        >导出</el-button>
      </el-col>
    </el-row> -->

    <el-table
        border stripe
        v-loading="loading"
        :data="devicefenceList"
        @selection-change="handleSelectionChange"
    >
       <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="序号" fixed align="center" type="index" />
      <el-table-column label="设备号" align="center" prop="device">
      </el-table-column>
      <el-table-column label="车牌号" align="center" prop="vehicleNumber">
      </el-table-column>
      <el-table-column label="起点围栏" align="center" prop="startFence">
        <template slot-scope="scope">
          <el-link v-for="fence in (scope.row.startFence || '').split(',')" :key="fence" type="primary" :href="'/schedule/mapfence?name='+fence">
            {{fence}}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="终点围栏" align="center" prop="endFence">
        <template slot-scope="scope">
          <el-link v-for="fence in (scope.row.endFence || '').split(',')" :key="fence" type="primary" :href="'/schedule/mapfence?name='+fence">
            {{fence}}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime">
        <template slot-scope="scope">{{ scope.row.createTime | formatDate }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed width="150" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除线路</el-button>
          <el-dropdown size="small" @command="(command) => handleCommand(command, scope.row)">
            <span class="el-dropdown-link">
              更多<i class="el-icon-d-arrow-right el-icon--left"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="!scope.row.vehicleNumber" command="toVehicle" icon="el-icon-view">查看车辆绑定</el-dropdown-item>
              <el-dropdown-item :disabled="!scope.row.device" command="toDevice" icon="el-icon-view">查看设备绑定</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    >
      <template v-slot:end>
        <div id="page-box" class="page-box">
          <div class="page-foot">
            <Button type="primary" @click="handleDeleteList(selectedRows)">批量删除</Button>
          </div>

        </div>
      </template>

    </pagination>


  </div>
</template>

<script>
import {
  listDevicefence,
  delDevicefence,
  addDevicefence,
  updateDevicefence,
  exportDevicefence,
  delDevicefenceList
} from "@/api/schedule/devicefence";
import Pagination from "./components/pagination.vue"
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


export default {
  name: "DeviceFence",
  components: {Pagination},
  props: {
    value: Boolean,
    device: String
  },
  data() {
    return {
      selectedRows: [],
      isAll: false,
      // 遮罩层
      loading: true,
      // 导出遮罩层
      exportLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 设备围栏关联表表格数据
      devicefenceList: [],
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        device: null,
        vehicleNumber: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      if (this.device) {
        this.queryParams.device = this.device
      }
      this.getList()
    },
    /** 查询设备围栏关联表列表 */
    getList() {
      this.loading = true;
      let form = Object.fromEntries(
        Object.entries(this.queryParams).map(
          ([k, v]) => [k, v === '' ? null : v]
        )
      )
      listDevicefence(form).then(response => {
        this.devicefenceList = response.data.list;
        this.total = response.data.count;
      }).finally(()=> {
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs.queryForm.resetFields()
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectedRows = selection; // 存储选中行的数据
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;

      this.$confirm('确认删除编号为「' + ids + '」的运输计划吗？').then(function() {
        return delDevicefence(ids);
      }).then(() => {
        this.getList();
        this.$Message.success("删除成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$modal.confirm('是否确认导出所有设备围栏关联表数据项？').then(() => {
        this.exportLoading = true;
        return exportDevicefence(queryParams);
      }).then(response => {
        this.$download.name(response.msg);
        this.exportLoading = false;
      }).catch(() => {});
    },
    toDevice(row) {
      this.$router.push({path: '/schedule/device', query: {device: row.device}})
    },
    toVehicle(row) {
      this.$router.push({path: '/schedule/device', query: {vehicle: row.vehicleNumber}})
    },
    handleCommand(command, row) {
      switch(command) {
        case 'toDevice':
          this.toDevice(row);
          return;
        case 'toVehicle':
          this.toVehicle(row);
          return;
      }
    },

    handleDeleteList(selectedRows) {
      if (selectedRows.length === 0) {
        this.$Message.warning("请先勾选要删除的路线");
        return;
      }
      const startEndFences = this.selectedRows.map(row => `${row.startFence} —— ${row.endFence}`).join('<br/>');
      this.$confirm(`确认删除以下路线：<br/>${startEndFences} 的运输计划吗？`, '提示', {
        dangerouslyUseHTMLString: true
      }).then(() => {
        const ids = this.selectedRows.map(row => row.id);
        return delDevicefenceList(ids);
      }).then(() => {
        this.getList();
        this.$Message.success("删除成功");
      }).catch(() => {});
    }
  }
};
</script>
<style scoped>
.el-link+.el-link {
  margin-left: 5px;
}
</style>
