import request from '@/libs/request'

var debug = false
import {getMapfenceByIds} from './mapfence'
var id = 1000;
var cache = [{
  id: 1,
  device: 'e123',
  vehicleNumber: '浙A19203',
  createTime: 1630930965,
  startFence: 'a,b',
  endFence: 'c'
},{
  id: 2,
  device: 'e234',
  vehicleNumber: '浙A93912',
  createTime: 1630960965,
  startFence: '',
  endFence: 'a,c'
}]

// 查询设备围栏关联表列表
export function listDevicefence(query) {
  if (debug) {
    let cached = cache.filter(c => (!query.device || c.device === query.device) && (!query.vehicleNumber || c.vehicleNumber === query.vehicleNumber))
    let start = Math.max((query.pageNum || 1) - 1, 0) * (query.pageSize || 10)
    let end = start + (query.pageSize || 10)
    return Promise.resolve({
      data:{
        count: cache.length,
        list: cached.slice(start, end)
      }
    });
  }
  return request({
    url: '/device_fence/list',
    method: 'post',
    data: query
  })
}

// 新增设备围栏关联表
export function addDevicefence(data) {
  if (debug) {
    data.id=id++;
    console.log(data)
    return getMapfenceByIds(data.startFenceId.split(','))
      .then((response) => {
        data.startFence = response.data.list.map(f => f.fenceName).join(',')
      })
      .then(() => {
        return getMapfenceByIds(data.endFenceId.split(','))
      })
      .then((response) => {
        data.endFence = response.data.list.map(f => f.fenceName).join(',')
      })
      .then(() => {
        cache.push(data)
        return {
          code: 200,
          msg: 'ok'
        }
      })
  }
  return request({
    url: '/device_fence/bind',
    method: 'post',
    data: data
  })
}

// 删除设备围栏关联表
export function delDevicefence(ids) {
  if (debug) {
    if (!Array.isArray(ids)) {
      ids = [ids]
    }
    let cached = cache.filter(c => ids.includes(c.id))
    if (cached.length) {
      cached.forEach(c => {
        cache.splice(cache.indexOf(c), 1);
      })
    }
    return Promise.resolve({
      code: 200,
      msg: 'ok'
    });
  }
  return request({
    url: '/device_fence/stop',
    method: 'delete',
    data: {id: ids}
  })
}

export function delDevicefenceList(ids) {
  return request({
    url: '/device_fence/batchStop',
    method: 'post',
    data: {ids: ids}
  })
}
